import React from "react";
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import monstersHourglass from '../assets/images/monsters3-selfguided.png';
import iconTopDiv from '../assets/icons/icon-top-div-green.png';
import safeZone1 from '../assets/images/safe-zone1.png';
import GetTheApp from "../components/ui/sections/GetTheApp";
import TheThreeRules from "../components/ui/sections/TheThreeRules";
import IconWaveTitle from "../components/ui/titles/IconWaveTitle";
import Card from "../components/ui/cards/Card";
import iconSafehouse from '../assets/icons/icon-safehouse.svg';
import iconCompass from '../assets/icons/icon-compass.png';
import { Link } from "gatsby";
import question from "../assets/icons/icon-question.svg";
import calmWater from "../assets/icons/icon-calm-water.svg";
import WaveTitle from "../components/ui/titles/WaveTitle";
import monsterGuide from '../assets/images/monster-guide.png';
import waveCalm from '../assets/icons/icon-wavy-calm.svg';
import How from "../components/content/How";
import ShareListenContent from "../components/content/ShareListen";
import GuideDownload from "../components/ui/GuideDownload";

const SelfGuidedSessions = () => {
    return (
        <Layout>
            <Seo seo={{ metaTitle: "Self Guided Sessions" }} />
            <main>
                <section id="safe-harbor-introduction">
                    <div className="container small">
                        <div className="flex wrap between items-center">
                        <div className="flex column w-60">
                            <img className="w-60 mb-4" src={waveCalm} alt="" />
                            <h1 className="grey">Self Guided Sessions At Home or Anywhere</h1>
                        </div>
                        {/* <img className="w-40" src={monstersHourglass} alt="" /> */}
                        </div>
                    </div>
                </section>
                <section id="safe-harbor-recommendation" className="bg-purple relative">
                    <img src={iconTopDiv} className="absolute" style={{width: "150px", top: "0", left: "50%", transform: "translate(-50%, -50%)"}} />
                    <div className="container small">
                        <div className="flex column items-center gap">
                            <h1 className="blue text-center">Recommended for Safe Harboring with your Partner:</h1>
                            <img src={safeZone1} className="w-30 my-5" />
                            <p className="large white text-center light">A safe space, a two minute timer and calm water.</p>
                        </div> 
                    </div>
                    <div className="flex center mt-4">
                        <div className="w-50 flex center text-center">
                            <GuideDownload/>
                        </div>
                    </div>
                </section>
                <section id="safe-harbor-setup" className="no-padding-bottom">
                    <div className="container small">
                        <IconWaveTitle title="Setup" icon={iconSafehouse} margin="mb-10" />
                        <div className="flex between gap">
                            <div className="w-60">
                                <p className="medium black">
                                    The Safe Harbor Method™ can be done in most places. For optimum results, we recommend:                                
                                </p>
                                <ul className="medium before-blue black mt-5">
                                    <li>
                                        Locate a time and place that works for both of you. If you cannot agree, reschedule. This is a mutual exercise. 
                                    </li>
                                    <li>
                                        Bring a timer.
                                    </li>
                                    <li>
                                        Try to create an environment that is quiet and private.
                                    </li>
                                    <li>
                                        Start with 30 seconds of silence
                                    </li>
                                    <li>
                                        Agree to the <Link to="#safe-harbor-the-rules" className="medium purple underline">3 rules</Link>
                                    </li>
                                </ul>
                            </div>

                            <div className="w-30">
                                <Card 
                                    icon={iconCompass}
                                >
                                    <p className="medium blue mt-4 text-center">Remember, you are steering this boat. Safe Harboring can be done anywhere, but it is important that you have quiet, calm and privacy.</p>
                                </Card>
                            </div>

                        </div>
                    </div>
                </section>
                <How />
                {/* <ShareListenContent /> */}
                
                <section id="safe-harbor-questions" className="">
                    <div className="container small">
                        <IconWaveTitle title="Practice Questions" icon={question} margin="mb-10" />
                        <div className="flex between gap">
                            <div className="w-60">
                                <p className="medium black">
                                    Take turns asking and answering any of these questions when practicing The Safe Harbor Method™:                                
                                </p>
                                <ul className="medium before-blue black mt-5">
                                    <li>
                                        What is on your mind?
                                    </li>
                                    <li>
                                        What have you liked most about today?
                                    </li>
                                    <li>
                                        What's important about today for you?
                                    </li>
                                    <li>
                                        What makes you more happy?
                                        <ul className="small-list medium mt-3">
                                            <li>Receiving Gifts?</li>
                                            <li>Words of Affirmation?</li>
                                            <li>Acts of Service?</li>
                                            <li>Quality Time?</li>
                                        </ul>
                                    </li>
                                    <li>
                                        What makes you sad?
                                    </li>
                                    <li>
                                        What are you looking forward to tomorrow?
                                    </li>
                                    <li>
                                        For what in you life do you feel most grateful?
                                    </li>
                                    <li>
                                        What do you value most in a friendship?
                                    </li>
                                    <li>
                                        What do you value most in OUR relationship?
                                    </li>
                                    <li>
                                        What was this process like for you?
                                    </li>
                                </ul>
                            </div>

                            <div className="w-30">
                                <Card 
                                    icon={calmWater}
                                >
                                    <p className="medium blue mt-4 text-center">Questioning and answering only truly works with the safety of calm water. To prevent interruptions it is imperative that you use the two minute timer while answering.</p>
                                </Card>
                            </div>

                        </div>
                    </div>
                </section>
                <TheThreeRules/>
                <GetTheApp/>
                <section>
                    <div className="container small flex column items-center">
                        {/* <img src={monsterGuide} alt="" style={{maxWidth: "250px"}} /> */}
                        <WaveTitle title="Need a Guide?" margin="my-5"/>
                        <div className="flex center gap">
                            <Link className="btn primary large" to="/choose-a-guide">Choose A Guide</Link>
                        </div>
                    </div>
                </section>
            </main>
        </Layout>
    )
}

export default SelfGuidedSessions;